import { useState, useEffect, useMemo } from "react";
import useYProvider from "y-partykit/react";
import "react-quill/dist/quill.snow.css";
import styles from "./Editor.module.css";

import * as Y from "yjs";
import { MonacoBinding } from "y-monaco";
import MonacoEditor from "@monaco-editor/react";

export default function Editor({
  room,
  userColor,
}: {
  room: string;
  userColor: string;
}) {
  const ydoc = useMemo(() => new Y.Doc(), []);
  const [editor, setEditor] = useState<any | null>(null);
  const provider = useYProvider({
    room,
    doc: ydoc,
  });
  const [binding, setBinding] = useState<MonacoBinding | null>(null);

  // this effect manages the lifetime of the editor binding
  useEffect(() => {
    if (provider == null || editor == null) {
      return;
    }
    console.log("reached", provider);
    provider.awareness.setLocalStateField("user", {
      name: "Typing...",
      color: userColor,
    });
    const binding = new MonacoBinding(
      ydoc.getText("monaco"),
      editor.getModel()!,
      new Set([editor]),
      provider.awareness
    );
    setBinding(binding);

    return () => {
      provider?.destroy();
      ydoc.destroy();
      binding.destroy();
    };
  }, [userColor, ydoc, provider, editor]);

  return (
    <div className={styles.editor}>
      <h1>
        Editor <code>Room #{room}</code>
      </h1>
      <MonacoEditor
        height="70vh"
        defaultValue="// some comment"
        defaultLanguage="javascript"
        onMount={(editor) => {
          setEditor(editor);
        }}
      />
    </div>
  );
}
